// @mui
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Header from './header';
//

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function DefaultLayout({ children }: Props) {
  return (
    <>
      <Header />
    
      <Container component="main" sx={{mt: 3}}>
        <Stack
          sx={{
            minHeight: 1,
            display: 'flex',
            flexDirection: { xs: 'column', lg: 'row' },
          }}
        >
          {children}
        </Stack>
      </Container>
    </>
  );
}
