import dayjs from 'dayjs';
import dayjsDuration, { Duration } from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(dayjsDuration);
dayjs.extend(relativeTime);

type InputValue = Date | string | number | null | undefined;

export function fDate(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'DD MMM YYYY';

  return date ? dayjs(date).format(fm) : '';
}

// Convert seconds to humanize
export function fHumanize(seconds: number) {
  return dayjs.duration(seconds * 1000).humanize();
}

// Convert seconds to humanize short
export function fHumanizeShort(seconds: number) {
  return shortHumanize(dayjs.duration(seconds * 1000));
}

export function shortHumanize(duration: Duration) {
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  const values = [
    days ? `${days}d` : '',
    hours ? `${hours}h` : '',
    minutes ? `${minutes}m` : '',
    seconds ? `${seconds}s` : '',
  ]
    .filter(Boolean)
    .slice(0, 2);

  return values.join(' ').trim();
}

// Convert seconds to minutes
export function fSecondsToTime(seconds: number, newFormat?: string) {
  const fm = newFormat || 'HH:mm:ss';

  return `${dayjs.duration(seconds * 1000).format(fm)}`;
}

export function fTime(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'HH:mm:ss';

  return date ? dayjs(date).format(fm) : '';
}

export function fDateTime(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'DD MMM YYYY HH:mm';

  return date ? dayjs(date).format(fm) : '';
}

export function fTimestamp(date: InputValue) {
  return date ? dayjs(date).millisecond() : '';
}

export function fToNow(date: InputValue) {
  return date ? dayjs(date).fromNow() : '';
}
