import { useTheme } from '@mui/material/styles';
import { AppBar, Chip, Container, Stack, Toolbar } from '@mui/material';
import Logo from 'src/components/logo';
import { RouterLink } from 'src/routes/components';
import { HEADER } from '../config-layout';
import { AccountPopover, HeaderShadow } from '../_common';

export default function Header() {
  const theme = useTheme();

  return (
    <AppBar
      sx={{
        position: 'relative',
      }}
    >
      <Container disableGutters>
        <Toolbar
          sx={{
            justifyContent: 'space-between',
            height: {
              xs: HEADER.H_MOBILE,
              md: HEADER.H_DESKTOP,
            },
            transition: theme.transitions.create(['height'], {
              easing: theme.transitions.easing.easeInOut,
              duration: theme.transitions.duration.shorter,
            }),
            //   ...(offsetTop && {
            //     ...bgBlur({
            //       color: theme.palette.background.default,
            //     }),
            //     height: {
            //       md: HEADER.H_DESKTOP_OFFSET,
            //     },
            //   }),
          }}
        >
          <Stack
            flex={1}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Logo />

            <Stack
              flexGrow={1}
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              spacing={{ xs: 0.5, sm: 1 }}
            >
              <Chip
                component={RouterLink}
                variant="outlined"
                label="Support"
                href="/"
              />
              <AccountPopover />
            </Stack>
          </Stack>
        </Toolbar>
      </Container>
      <HeaderShadow />
    </AppBar>
  );
}
