import _graphqlRequest, { RequestOptions, Variables } from 'graphql-request';
import { QueryClient } from '@tanstack/react-query';
import { accessToken } from 'src/auth/context/auth0';

export const queryClient = new QueryClient();

export const BASE_URL = `${process.env.REACT_APP_AFFILIATE_BASE_API_URL!}`;

export async function graphqlRequest<T, V extends Variables = Variables>(
  options: RequestOptions<V, T>
): Promise<T> {
  const token = await accessToken.getAccessTokenSilently()();

  options.requestHeaders = {
    ...options.requestHeaders,
    authorization: `Bearer ${token}`,
  };

  return _graphqlRequest({ ...options, url: `${BASE_URL}/graphql` });
}

export async function restRequest<T>({
  url,
  ...init
}: { url: string } & RequestInit): Promise<T> {
  const token = await accessToken.getAccessTokenSilently()();

  init.headers = {
    ...init.headers,
    authorization: `Bearer ${token}`,
  };

  return fetch(`${BASE_URL}/${url}`, init).then((res) => res.json());
}
