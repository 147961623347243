import { LoadingScreen } from 'src/components/loading-screen';
import {
  Navigate,
  Outlet,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';
import { Suspense, lazy } from 'react';

import AuthClassicLayout from 'src/layouts/auth/classic';
import CompactLayout from 'src/layouts/compact';
import { AuthGuard, GuestGuard } from 'src/auth/guard';
import DefaultLayout from 'src/layouts/default';

const AuthPage = lazy(() => import('src/pages/auth'));
const HomePage = lazy(() => import('src/pages/home'));
const Page404 = lazy(() => import('src/pages/404'));

const router = createBrowserRouter([
  // Auth routes
  {
    path: 'auth',
    element: (
      <GuestGuard>
        <AuthClassicLayout>
          <AuthPage />
        </AuthClassicLayout>
      </GuestGuard>
    ),
  },

  // Dashboard routes
  {
    path: '/',
    element: (
      <AuthGuard>
        <DefaultLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DefaultLayout>
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        element: <HomePage />,
      },
    ],
  },

  // Main routes
  {
    element: (
      <CompactLayout>
        <Outlet />
      </CompactLayout>
    ),
    children: [{ path: '404', element: <Page404 /> }],
  },

  // No match 404
  { path: '*', element: <Navigate to="/404" replace /> },
]);

export function AppRouter() {
  return <RouterProvider router={router} />;
}
